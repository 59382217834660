<template>
  <div class="profile-container">
    <div class="header">
      <h1 class="profile-title">Profile</h1>
      <div class="save-container">
        <div v-if="!validForm" class="error-display">
          <p class="error-label">Please check all fields again.</p>
        </div>
        <v-btn class="save-btn" @click="edit()" color="primary">Save</v-btn>
      </div>
    </div>

    <h2 class="panel-title">General</h2>

    <v-form ref="form">
      <p class="input-label">
        Company Name
        <span>*</span>
      </p>
      <v-text-field
        name="company-name"
        v-model="user.company.company_name"
        outlined
        :rules="[rules.required]"
      ></v-text-field>
      <p class="input-label">
        Email
        <span>*</span>
      </p>
      <v-text-field
        name="email"
        v-model="user.email"
        outlined
        :rules="[rules.required, rules.email]"
      ></v-text-field>
      <p class="input-label">
        Company Logo
        <span>*</span>
      </p>
      <ImagePicker
        :existing-src="user.company.logo"
        @change="imageChange"
        :require="true"
      />
      <p class="input-label">
        Phone Number
        <span>*</span>
      </p>
      <v-text-field
        name="phone"
        v-model="user.company.tel"
        type="text"
        outlined
        :rules="[rules.required]"
      ></v-text-field>

      <AddressPicker
        v-if="showAddressPicker"
        :base-url="baseURL"
        v-model="user.address"
        :rules="[rules.required]"
      >
        <template #map>
          <StaticMap
            :maps-key="mapsKey"
            :lat="user.address.lat"
            :lng="user.address.lng"
          />
        </template>
      </AddressPicker>
      <template v-else>
        <p class="input-label">
          Address
          <span>*</span>
        </p>
        <v-textarea
          name="address"
          v-model="user.address.formatted_address"
          outlined
          type="text"
          :rules="[rules.required]"
          @keyup="address_entered"
        ></v-textarea>
      </template>
      <p class="input-label">
        Type of Care Provided
        <span>*</span>
      </p>
      <p class="input-hint">
        Please select which types of care your company can provide
      </p>
      <v-select
        outlined
        v-model="user.company.care_type_provided"
        :items="careTypes"
        :menu-props="{ maxHeight: '400' }"
        label="Select Care Type"
        multiple
        chips
        :rules="[rules.requiredSelected]"
      >
        <template #selection="{ item }">
          <CareType :care-type="item" /> </template
      ></v-select>
      <p class="input-label">
        Area you cover
        <span>*</span>
      </p>
      <p class="input-hint">
        For each type of care that you provide, please select the area you cover
        in miles. You will not receive requests for this type of care outside
        your coverage area. For example, if you select 2 for ‘hourly care’, you
        will only receive hourly care requests where the postcode of the person
        requesting care is within a 2 mile radius of your postcode (as stated on
        your profile). If you cover the entire country (for example for live-in
        care), please tick ‘All of England’.
      </p>
      <CoverageSelector
        v-model="user.company.coverage"
        :care-types="providedCareTypes"
        :lat="lat"
        :lng="lng"
      />

      <div class="split-input-container">
        <div>
          <p class="input-label">
            First Name
            <span>*</span>
          </p>
          <v-text-field
            name="first_name"
            outlined
            v-model="user.company.first_name"
            type="text"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
        <div>
          <p class="input-label">
            Last Name
            <span>*</span>
          </p>
          <v-text-field
            name="last_name"
            outlined
            v-model="user.company.last_name"
            type="text"
            :rules="[rules.required]"
          ></v-text-field>
        </div>
      </div>
      <p class="input-label">Website</p>
      <v-text-field outlined v-model="user.company.website"></v-text-field>
      <p class="input-label">
        CQC Location URL
        <span>*</span>
      </p>
      <p class="input-hint">
        This is your cqc location link. Example:
        https://www.cqc.org.uk/location/1-23456789
      </p>
      <v-text-field
        outlined
        v-model="user.company.cqc_profile"
        :rules="[rules.required, rules.cqc]"
      ></v-text-field>
      <CQCProfile :link="this.user.company.cqc_profile" :base-url="baseURL" />

      <p class="input-label">
        Bio
        <span>*</span>
      </p>
      <v-textarea
        outlined
        type="text"
        v-model="user.company.bio"
        :rules="[rules.required]"
      ></v-textarea>

      <v-spacer></v-spacer>
    </v-form>

    <div v-if="showServices">
      <h2 class="panel-title">Billing</h2>

      <v-form ref="form">
        <p class="input-label">
          Billing Name
          <span>*</span>
        </p>
        <v-text-field
          name="billing-name"
          v-model="user.billing_details.name"
          outlined
          :rules="[rules.required]"
        ></v-text-field>
        <AddressPicker
          v-if="showAddressPicker"
          :base-url="baseURL"
          v-model="user.billing_details.address"
          :rules="[rules.required]"
        >
          <template #map>
            <StaticMap
              :maps_key="mapsKey"
              :lat="user.billing_details.address.lat"
              :lng="user.billing_details.address.lng"
            />
          </template>
        </AddressPicker>
        <template v-else>
          <p class="input-label">
            Address
            <span>*</span>
          </p>
          <v-textarea
            name="address"
            v-model="user.billing_details.address.formatted_address"
            outlined
            type="text"
            :rules="[rules.required]"
          ></v-textarea>
        </template>

        <div class="split-input-container">
          <div>
            <p class="input-label">
              Account Number
              <span>*</span>
            </p>
            <v-text-field
              name="account-number"
              v-model="user.billing_details.account_number"
              outlined
              :rules="[rules.required]"
              type="number"
            ></v-text-field>
          </div>
          <div>
            <p class="input-label">
              Sort Code
              <span>*</span>
            </p>
            <v-text-field
              name="sort_code"
              v-model="user.billing_details.sort_code"
              outlined
              :rules="[rules.required]"
              type="number"
            ></v-text-field>
          </div>
        </div>
        <div class="split-input-container">
          <div>
            <p class="input-label">
              Company Number
              <span>*</span>
            </p>
            <v-text-field
              name="company-number"
              v-model="user.billing_details.company_number"
              outlined
              :rules="[rules.required]"
              type="number"
            ></v-text-field>
          </div>
          <div>
            <p class="input-label">
              VAT Number
              <span>*</span>
            </p>
            <v-text-field
              name="vat-number"
              v-model="user.billing_details.vat_number"
              outlined
              :rules="[rules.required]"
              type="number"
            ></v-text-field>
          </div>
        </div>
      </v-form>
    </div>

    <h2 class="panel-title">Legal & Compliance</h2>
    <LegalConsent
      :name="user.company.company_name"
      :email="user.email"
      :documents="documents"
      @consent="consent"
    />
  </div>
</template>

<script>
import EventBus from "@/event-bus.js"

import CQCProfile from "component-library/src/components/CQCProfile/CQCProfile.vue"
import AddressPicker from "component-library/src/components/AddressPicker/AddressPicker.vue"
import StaticMap from "component-library/src/components/StaticMap/StaticMap.vue"
import LegalConsent from "component-library/src/components/LegalConsent/LegalConsent.vue"
import ImagePicker from "component-library/src/components/ImagePicker/ImagePicker.vue"
import CareType from "@/components/careType.vue"
import CoverageSelector from "@/components/coverageSelector.vue"

import { GetProfile, UpdateProfile, GeocodeAddress } from "@/api/profile"
import { UploadFile } from "@/api/storage"
import { SearchCQC } from "@/api/cqc/search"
import {
  SHOW_CQC,
  BASE_URL,
  COMPANY_SERVICE_PORT,
  MAPS_KEY,
  SHOW_SERVICES,
  SHOW_ADDRESS_PICKER
} from "@/config"
import { ConsentToLegalDocument } from "@/api/legal/consent_to_legal_document"
import { GetCurrentLegalDocuments } from "@/api/legal/get_current_legal_documents"
export default {
  components: {
    CQCProfile,
    AddressPicker,
    StaticMap,
    LegalConsent,
    ImagePicker,
    CareType,
    CoverageSelector
  },
  data() {
    return {
      show_cqc: SHOW_CQC,
      loading: false,
      items: [],
      search: null,
      select: null,
      mapsKey: MAPS_KEY,
      showServices: SHOW_SERVICES,
      showAddressPicker: SHOW_ADDRESS_PICKER,
      baseURL: `${BASE_URL}${COMPANY_SERVICE_PORT}`,
      validForm: true,

      imageData: null,
      rules: {
        required: value => !!value || "Required",
        requiredSelected: value => {
          if (value === null) {
            return "Required"
          }
          return value.length > 0 || "Required"
        },
        requiredRadio: value => value !== null || "Required",

        // sort_code: value => {
        //   const pattern = /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/;
        //   return pattern.test(value) || "Invalid sort code";
        // },
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          return pattern.test(value) || "Invalid email"
        },
        cqc: value => {
          const pattern = /https:\/\/www.cqc.org.uk\/location\/[A-Z0-9-]{1,14}$/
          return pattern.test(value) || "Invalid cqc location link"
        }
      },
      careTypes: ["Hourly Care", "Live In Care", "Night Time Care"],
      user: {
        company: {
          company_name: "",
          coverage: {}
        },
        address: {
          formatted_address: ""
        },
        billing_details: {
          name: "",
          address: {
            formatted_address: ""
          }
        }
      },
      timeout: null,
      lat: null,
      lng: null
    }
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    }
  },
  async created() {
    await GetProfile()
    this.user = this.$store.getters.profile
    this.lat = this.user.address.lat
    this.lng = this.user.address.lng
  },
  computed: {
    documents() {
      return this.$store.getters.legalDocuments
    },
    providedCareTypes: {
      cache: false,
      get() {
        return this.user.company.care_type_provided
      }
    }
  },
  methods: {
    async querySelections(v) {
      clearTimeout(this._timerId)

      this._timerId = setTimeout(async () => {
        this.loading = true
        this.items = await SearchCQC(v)
        this.loading = false
      }, 500)
    },
    imageChange(image) {
      if (image) {
        this.imageData = image
      }
    },
    async edit() {
      this.validForm = this.$refs.form.validate()
      if (this.validForm) {
        const databody = new FormData()
        databody.append("img", this.imageData)

        if (this.imageData != null) {
          this.user.company.logo = await UploadFile(databody)
        }
        if (this.select != null) {
          this.user.company.cqc_rating = this.select
        }
        await UpdateProfile(this.user)
        this.user = await GetProfile()
        EventBus.$emit("toast", {
          text: "Updated Profile",
          type: "success"
        })
      }
    },
    async consent(id) {
      await ConsentToLegalDocument(id)
      await GetCurrentLegalDocuments(true)
    },
    async address_entered() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        if (this.user?.address?.formatted_address?.length > 0) {
          const res = await GeocodeAddress(this.user.address.formatted_address)
          this.lat = res.lat
          this.lng = res.lng
        }
      }, 300)
    }
  }
}
</script>

<style scoped>
.profile-container {
  max-width: 650px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: sticky;
  top: 105px;
  z-index: 3;
  background: white;
  padding: 10px;
  /* box-shadow: 0 4px 2px -2px rgba(0,0,0,0.16); */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.save-container {
  display: flex;
  flex-direction: row;
}

::v-deep .v-btn__content {
  font-size: 18px;
}

.error-display {
  display: flex;
  justify-content: flex-end;
  margin: 10px 10px 0 10px;
}
.error-label {
  color: var(--v-red-base);
  margin: 0;
}

.profile-title {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-align: center;
  color: var(--v-grey-base);
}
.panel-title {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: normal;
  color: var(--v-grey-base);
}
::v-deep .input-label {
  font-size: 20px;
  letter-spacing: normal;
  margin-top: 10px;
  color: var(--v-grey-base);
}
.input-label span {
  vertical-align: super;
  color: #ff0000;
}
.input-hint {
  color: var(--v-grey-lighten1);
}
::v-deep .v-input__slot {
  background: #fff !important;
  border-radius: 10px;
}
.split-input-container {
  display: flex;
  justify-content: space-between;
}
.split-input-container > div {
  width: 49%;
}

@media only screen and (max-height: 720px) {
  .header {
    top: 62px;
  }
}

@media only screen and (max-width: 1281px) {
  .profile-title {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .name-container {
    flex-direction: column;
  }
  #first-name {
    width: 100%;
  }
  #last-name {
    width: 100%;
  }
}
</style>
