import { DateTime } from "luxon"

import { LegalService, LegalUnauthedService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"
import store from "@/store"

export const GetCurrentLegalDocuments = async (authed = false) => {
  try {
    let docs = []
    if (authed) {
      const {
        data: { Documents }
      } = await LegalService().get("/legal/documents/company")
      docs = Documents
    } else {
      let {
        data: { Documents }
      } = await LegalUnauthedService.get("/legal/documents/company")
      docs = Documents
    }

    store.commit(
      "set_legal_documents",
      docs.map(doc => {
        doc.created_at = DateTime.fromISO(doc.created_at).toLocaleString(
          DateTime.DATETIME_SHORT
        )

        if (doc.consented_at != null) {
          doc.consented_at = DateTime.fromISO(doc.consented_at).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        }

        doc.name = doc.filename.replace(/\.[^/.]+$/, "")
        return doc
      })
    )
  } catch (e) {
    DeAuthSession(e)
  }
}
