import axios from "axios"
import { BASE_URL, COMPANY_SERVICE_PORT } from "@/config"
import store from "@/store"
import { DeAuthSession } from "@/api/de-auth"

export const UploadFile = async data => {
  try {
    let {
      data: { link }
    } = await axios({
      method: "post",
      data,
      url: `${BASE_URL}${COMPANY_SERVICE_PORT}/file`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${store.getters.token}`
      }
    })
    return link
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
