import { LegalService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const ConsentToLegalDocument = async id => {
  try {
    await LegalService().post(`/legal/documents/${id}`)
  } catch (e) {
    DeAuthSession(e)
  }
}
